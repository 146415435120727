import React, { useContext } from 'react';
import { Box } from '@og-pro/ui';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { ProjectCreateV2FunctionsContext } from '../../context';
import {
    BaseSectionsNavBackNextButtons,
    BaseSectionsNavBackButton,
    BaseSectionsNavNextButton,
    SectionHeaderViewTitleOnly,
} from '../../../../../../components/SDv2';
import { SharedTextareaToolbar } from '../../../../../../components/SDv2/SharedTextareaToolbar';
import { fieldNames } from '../../../constants';
import { SignatureForm } from '../../../../../../components';
import { getTemplateVariableOptions } from '../../../../constants';
import { getProjectSectionTagOptions } from '../../../../selectors';

const { SIGNATURES } = fieldNames;

export const ProjectCreateSignatures = () => {
    const tagOptions = useSelector(getProjectSectionTagOptions);

    const {
        buildRouteFunction,
        change,
        disabled,
        form,
        initiateSneakyUpdate,
        project,
        showContractCompiler,
        showFormErrors,
    } = useContext(ProjectCreateV2FunctionsContext);
    const templateVariableOptions = getTemplateVariableOptions({ project });

    const signatures = useSelector((state) => formValueSelector(form)(state, SIGNATURES));
    const signatureIndex = signatures?.length ? 0 : -1;
    const signature = signatureIndex > -1 ? signatures[signatureIndex] : null;

    const styles = require('./index.scss');

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <SectionHeaderViewTitleOnly
                    description="Create a signature page by adding signatories and they will auto-format within your contract document. This page is optional."
                    title="Signatures"
                />
            </Box>
            <SharedTextareaToolbar />
            <Box className={styles.content}>
                {!!signature && (
                    <Box>
                        <SignatureForm
                            {...{
                                change,
                                disabled,
                                form,
                                tagOptions,
                                templateVariableOptions,
                            }}
                            formKey={`${SIGNATURES}.${signatureIndex}`}
                            showValidation={showFormErrors}
                        />
                    </Box>
                )}
            </Box>
            <Box className={styles.footer}>
                <BaseSectionsNavBackNextButtons
                    firstSectionComponent={
                        <BaseSectionsNavBackButton
                            onClick={() => initiateSneakyUpdate(buildRouteFunction('attachments'))}
                        >
                            Attachments
                        </BaseSectionsNavBackButton>
                    }
                    lastSectionComponent={
                        <BaseSectionsNavNextButton
                            onClick={() =>
                                initiateSneakyUpdate(buildRouteFunction('review-checklist'))
                            }
                        >
                            {`${showContractCompiler ? '' : 'Final '}Review Checklist`}
                        </BaseSectionsNavNextButton>
                    }
                    sections={[]}
                />
            </Box>
        </Box>
    );
};
