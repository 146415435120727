const validateCheckboxOptions = () => {};
const validateDateOptions = () => {};
const validateDropdownOptions = () => {};
const validateLongTextOptions = () => {};
const validateMultipleChoiceOptions = () => {};
const validateSingleChoiceOptions = () => {};
const validateNumberOptions = (values) => {
    const errors = {};

    if (!values.typeOfNumber) {
        errors.typeOfNumber = 'Please specify a type of number';
    }

    return errors;
};
const validateShortTextOptions = () => {};
const validateYesNoOptions = () => {};

export {
    validateCheckboxOptions,
    validateDateOptions,
    validateDropdownOptions,
    validateLongTextOptions,
    validateMultipleChoiceOptions,
    validateSingleChoiceOptions,
    validateNumberOptions,
    validateShortTextOptions,
    validateYesNoOptions,
};
