import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Box, Typography } from '@og-pro/ui';

import { Toggle } from '../../../../../../../components';

export const PostingOptionToggle = ({
    disabled,
    fieldName,
    info,
    label,
    onChange,
    subHeading,
    uiForceDisabled,
}) => {
    return (
        <>
            <Box paddingBottom={0.5}>
                <Typography variant="h4">{subHeading}</Typography>
            </Box>
            <Box className="text-muted" paddingBottom={0.5}>
                <Typography variant="p">{info}</Typography>
            </Box>
            {uiForceDisabled === true ? (
                <Toggle
                    aria-label={label}
                    disabled
                    input={{
                        onBlur: () => {},
                        onChange,
                        value: false,
                    }}
                    leftLabel="NO"
                    name={fieldName}
                    qaTag={`postingOptions-${label}`}
                    rightLabel="YES"
                    useOpenGovStyle
                />
            ) : (
                <Field
                    aria-label={label}
                    component={Toggle}
                    disabled={disabled}
                    leftLabel="NO"
                    name={fieldName}
                    onChange={onChange}
                    qaTag={`postingOptions-${label}`}
                    rightLabel="YES"
                    useOpenGovStyle
                />
            )}
        </>
    );
};

PostingOptionToggle.propTypes = {
    disabled: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    subHeading: PropTypes.string.isRequired,
    uiForceDisabled: PropTypes.bool,
};
