import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { withRouter } from '@og-pro-migration-tools/react-router';

import {
    helpAutoBidUnsealed,
    helpDisabledSubstitutionRequests,
    helpPrivateBid,
    helpRequiredInvitation,
    helpPublicPricingResults,
    helpPublicResponsesResults,
    helpQaEnabled,
    helpSealedBid,
    helpSubstitutionRequests,
    helpVendorFollowers,
    postOnlyInstructions,
    publicPricingDisplayOptions,
    writingFlowInstructions,
    helpNotifyVendorsPosting,
} from './constants';
import { form, formConfig, fieldNames } from '../constants';
import { mapStateToProps as projectPostCreateMapStateToProps } from '../mapProps';
import { getBidPosterAuthorized } from '../../selectors';
import { Checkbox, Main, SearchSelect, SectionTitle, Toggle, Well } from '../../../../components';
import { Timeline } from '../../../../components/GovApp';
import { extendSimpleWizardForm } from '../../../../hocs';
import { isTemplateAdminUser } from '../../../selectors';
import {
    getPostingDate,
    getReleaseProjectDate,
    isScheduledDateReleaseDate,
} from '../PostConfirmationModal/selectors';
import { postDateScheduledDateWarning } from '../helpers';

const {
    ALLOW_SUBSTITUTION_REQUESTS,
    HAS_SEALED_BID,
    IS_AUTO_BID_UNSEALED,
    IS_PRIVATE,
    IS_PUBLIC_BID_PRICING_RESULT,
    IS_PUBLIC_BID_RESULT,
    IS_PUBLIC_BID_TOTAL_ONLY,
    IS_TIMELINE_CONFIRMED,
    NOTIFY_VENDORS_ON_POSTING,
    QA_ENABLED,
    REQUIRES_INVITATION,
    SHOW_PLANHOLDERS,
} = fieldNames;

const mapStateToProps = (state, props) => {
    return {
        ...projectPostCreateMapStateToProps(state, props),
        hasSealedBid: formValueSelector(form)(state, HAS_SEALED_BID),
        isBidPoster: getBidPosterAuthorized(state),
        isPublicBidPricingResult: formValueSelector(form)(state, IS_PUBLIC_BID_PRICING_RESULT),
        isPrivate: formValueSelector(form)(state, IS_PRIVATE),
        isScheduledDateReleaseDate: isScheduledDateReleaseDate(state),
        postingDate: getPostingDate(state),
        releaseProjectDate: getReleaseProjectDate(state),
        templateAdminUser: isTemplateAdminUser(state),
    };
};

// @connect
// @reduxForm
// @extendSimpleWizardForm
class ConnectedProjectTimeline extends Component {
    static propTypes = {
        array: PropTypes.object.isRequired,
        change: PropTypes.func.isRequired,
        hasSealedBid: PropTypes.bool,
        isBidPoster: PropTypes.bool.isRequired,
        isPublicBidPricingResult: PropTypes.bool,
        isPrivate: PropTypes.bool,
        isScheduledDateReleaseDate: PropTypes.bool.isRequired,
        postingDate: PropTypes.instanceOf(Date),
        project: PropTypes.shape({
            isPostOnly: PropTypes.bool.isRequired,
            template: PropTypes.shape({
                forceSealedBidStatus: PropTypes.bool,
                isReverseAuction: PropTypes.bool,
                substitutionRequestFormUrl: PropTypes.string,
            }),
        }).isRequired,
        releaseProjectDate: PropTypes.instanceOf(Date),
        showFormErrors: PropTypes.bool.isRequired,
        templateAdminUser: PropTypes.bool.isRequired,
        timezone: PropTypes.string.isRequired,
        updating: PropTypes.bool,
    };

    get styles() {
        return require('./ProjectTimeline.scss');
    }

    sealedBidChangeHandler = (e, hasSealedBid) => {
        const { change } = this.props;

        if (!hasSealedBid) {
            change(IS_AUTO_BID_UNSEALED, false);
            change(IS_PUBLIC_BID_PRICING_RESULT, false);
            change(IS_PUBLIC_BID_RESULT, false);
        }
    };

    tooltipHelper = (helpText, disableSealedBidSettings) => {
        const nonAdminText = 'Only template administrators can change this setting.';

        if (disableSealedBidSettings) {
            return `${helpText} ${nonAdminText}`;
        }

        return helpText;
    };

    render() {
        const {
            array,
            change,
            hasSealedBid,
            isBidPoster,
            isPublicBidPricingResult,
            isPrivate,
            postingDate,
            project: {
                isPostOnly,
                template: { forceSealedBidStatus, isReverseAuction, substitutionRequestFormUrl },
            },
            releaseProjectDate,
            showFormErrors,
            templateAdminUser,
            timezone,
            updating,
        } = this.props;

        const sectionInstructions = isPostOnly ? postOnlyInstructions : writingFlowInstructions;
        const disableSealedBidSettings = forceSealedBidStatus && !templateAdminUser;

        return (
            <Main className="row">
                <div className="col-md-offset-1 col-md-10">
                    <SectionTitle info={sectionInstructions} title="Timeline" />
                    <Timeline
                        array={array}
                        change={change}
                        disabled={updating}
                        form={form}
                        project={this.props.project}
                        showValidation={showFormErrors}
                        timezone={timezone}
                    />
                    <div className={this.styles.confirmCheckbox}>
                        <Field
                            component={Checkbox}
                            disabled={updating}
                            inline
                            name={IS_TIMELINE_CONFIRMED}
                            showValidation={showFormErrors}
                            text="Please confirm all dates are correct (required)"
                        />
                    </div>
                    {!this.props.isScheduledDateReleaseDate && isBidPoster && (
                        <p className="text-warning text-center">
                            <i className="fa fa-exclamation-triangle" /> Warning:
                            <br />
                            {postDateScheduledDateWarning(postingDate, releaseProjectDate)}
                        </p>
                    )}
                    <div className="row">
                        <div className="col-sm-offset-1 col-sm-10">
                            <Well className={this.styles.postOptionsContainer}>
                                <div className={this.styles.postBody}>
                                    <h4>Posting Options</h4>
                                    <Field
                                        component={Toggle}
                                        disabled={updating}
                                        label="Make Private Bid?"
                                        labelInline
                                        name={IS_PRIVATE}
                                        onChange={(e, value) => {
                                            if (!value) {
                                                change(REQUIRES_INVITATION, false);
                                            }
                                        }}
                                        tooltip={helpPrivateBid}
                                    />
                                    {isPrivate && (
                                        <Field
                                            component={Toggle}
                                            disabled={updating}
                                            label="Require Invitation to Access Bid"
                                            labelInline
                                            name={REQUIRES_INVITATION}
                                            tooltip={helpRequiredInvitation}
                                        />
                                    )}
                                    <Field
                                        component={Toggle}
                                        disabled={updating}
                                        label="Automatically Notify Subscribed Vendors?"
                                        labelInline
                                        name={NOTIFY_VENDORS_ON_POSTING}
                                        tooltip={helpNotifyVendorsPosting}
                                    />
                                    <Field
                                        component={Toggle}
                                        disabled={updating}
                                        label="Publicly List Vendor Followers?"
                                        labelInline
                                        name={SHOW_PLANHOLDERS}
                                        tooltip={helpVendorFollowers}
                                    />
                                    <Field
                                        component={Toggle}
                                        disabled={!substitutionRequestFormUrl || updating}
                                        label="Allow Requests for Substitution?"
                                        labelInline
                                        name={ALLOW_SUBSTITUTION_REQUESTS}
                                        tooltip={
                                            substitutionRequestFormUrl
                                                ? helpSubstitutionRequests
                                                : helpDisabledSubstitutionRequests
                                        }
                                    />
                                    <Field
                                        component={Toggle}
                                        disabled={updating}
                                        label="Enable Q&A?"
                                        labelInline
                                        name={QA_ENABLED}
                                        tooltip={helpQaEnabled}
                                    />
                                    <Field
                                        component={Toggle}
                                        disabled={
                                            isReverseAuction || disableSealedBidSettings || updating
                                        }
                                        label="Use Sealed Bid Process?"
                                        labelInline
                                        name={HAS_SEALED_BID}
                                        onChange={this.sealedBidChangeHandler}
                                        tooltip={this.tooltipHelper(
                                            helpSealedBid,
                                            disableSealedBidSettings
                                        )}
                                    />
                                    {hasSealedBid && (
                                        <div className={this.styles.sealedBidOptions}>
                                            <h5>Sealed Bid Options</h5>
                                            <Field
                                                component={Toggle}
                                                disabled={
                                                    isReverseAuction ||
                                                    disableSealedBidSettings ||
                                                    updating
                                                }
                                                label="Automatically Unseal Bid?"
                                                labelInline
                                                name={IS_AUTO_BID_UNSEALED}
                                                tooltip={this.tooltipHelper(
                                                    helpAutoBidUnsealed,
                                                    disableSealedBidSettings
                                                )}
                                            />
                                            <Field
                                                component={Toggle}
                                                disabled={disableSealedBidSettings || updating}
                                                label="Publish Vendor Pricing Results?"
                                                labelInline
                                                name={IS_PUBLIC_BID_PRICING_RESULT}
                                                tooltip={this.tooltipHelper(
                                                    helpPublicPricingResults,
                                                    disableSealedBidSettings
                                                )}
                                            />
                                            {isPublicBidPricingResult && (
                                                <Field
                                                    component={SearchSelect}
                                                    disabled={disableSealedBidSettings || updating}
                                                    isSearchable={false}
                                                    name={IS_PUBLIC_BID_TOTAL_ONLY}
                                                    options={publicPricingDisplayOptions}
                                                />
                                            )}
                                            <Field
                                                component={Toggle}
                                                disabled={disableSealedBidSettings || updating}
                                                label="Publish Vendor Responses?"
                                                labelInline
                                                name={IS_PUBLIC_BID_RESULT}
                                                tooltip={this.tooltipHelper(
                                                    helpPublicResponsesResults,
                                                    disableSealedBidSettings
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Well>
                        </div>
                    </div>
                </div>
            </Main>
        );
    }
}

export const ProjectTimeline = compose(
    withRouter,
    connect(mapStateToProps),
    reduxForm(formConfig),
    extendSimpleWizardForm()
)(ConnectedProjectTimeline);
