import { Box, Button, ReduxFormTextField, Tooltip, Typography } from '@og-pro/ui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useDispatch } from 'react-redux';

import { fieldTypesArray } from '@og-pro/shared-config/customFormService/customField';

import { FieldTypeOptions } from '../FieldTypeOptions';
import { createCustomFieldInitialFieldsDict, formConfig } from './form';

const { NAME, SHORT_TITLE, INSTRUCTIONS_TEXT } = createCustomFieldInitialFieldsDict;

export const CreateCustomFieldSettings = ({ fieldType }) => {
    const dispatch = useDispatch();
    const [showShortTitleField, setShowShortTitleField] = useState(false);

    function handleAddShortTitleClick() {
        setShowShortTitleField(true);
    }

    function handleRemoveShortTitleClick() {
        dispatch(change(formConfig.form, SHORT_TITLE, null));
        setShowShortTitleField(false);
    }

    return (
        <Box
            component="form"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <Field component={ReduxFormTextField} label="Question / Title *" name={NAME} />
            {showShortTitleField ? (
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Field
                        characterLimit={100}
                        component={ReduxFormTextField}
                        description="Shortened description that will show up us a column header in a report."
                        label="Short Title"
                        name={SHORT_TITLE}
                        sx={{ width: '540px' }}
                    />
                    <Box sx={{ height: '100%', alignItems: 'end', display: 'flex' }}>
                        <Button
                            color="secondary"
                            onClick={handleRemoveShortTitleClick}
                            sx={{
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                                paddingLeft: 1,
                                paddingRight: 1,
                            }}
                            variant="text"
                        >
                            <DeleteIcon fontSize="small" />
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                        color="secondary"
                        onClick={handleAddShortTitleClick}
                        startIcon={<AddIcon />}
                        sx={{ padding: 0 }}
                        variant="text"
                    >
                        Add Short Title
                    </Button>
                    <Tooltip title="The short title will show up in the report as a column header in the report">
                        <InfoOutlinedIcon
                            color="secondary"
                            fontSize="small"
                            sx={{ height: '100%' }}
                        />
                    </Tooltip>
                </Box>
            )}
            <Box>
                <Field
                    component={ReduxFormTextField}
                    description="Optional secondary information intended to guide users on how to answer the question."
                    label="Instructions"
                    name={INSTRUCTIONS_TEXT}
                    sx={{ width: '100%' }}
                />
                {/* TODO: Implement Show Instructions As RadioGroup */}
            </Box>
            <Box display="flex" flexDirection="column" gap={2} pt={1}>
                <Typography variant="h3">The Answer</Typography>
                <FieldTypeOptions fieldType={fieldType} />
            </Box>
        </Box>
    );
};

CreateCustomFieldSettings.propTypes = {
    fieldType: PropTypes.oneOf(fieldTypesArray).isRequired,
};
