import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from '@og-pro-migration-tools/react-router';
import { compose } from 'redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Outlet } from 'react-router-dom';
import { Box, TabsNavigation } from '@og-pro/ui';
import { withFlags, FLAGS } from '@og-pro/launch-darkly/client';

import { projectStatusesDict, projectTypesDict } from '@og-pro/shared-config/projects';

import { basicUserRoles } from '@og-pro/shared-config/userRoles';

import { formConfig as projectCreateFormConfig } from '../constants';
import {
    getBuilderSectionsJS,
    getDeserializedProject,
    getLoadInitialDataError,
    getProjectJS,
    getProjectSection,
    getSectionRoutes,
    getWritingSections,
    isConfirmationPage,
    isLoadingInitialData,
    isPseudoSectionPage,
} from '../selectors';
import { validateAll as validate, warn } from '../validate';
import {
    getBuilderDisplayName,
    getDashboardPath,
    getDocumentReviewPath,
    getDocumentWritingPath,
    isBudgetRequired as getIsBudgetRequired,
    hasAutoAddendaSubscription,
    isBuilderSaveValid,
    isBudgetUsed,
    isDocumentEditable,
    isDocumentEditor,
    isProjectIdRequired,
    isIntakeDraftPage,
    isBuilderDraftPage,
} from '../../selectors';
import connectData from '../../../ConnectData';
import { getUserJS, isInitialClientLoaded } from '../../../selectors';
import { shouldLoadSuggestedContent, loadSuggestedContent } from '../../../../actions/admin';
import { shouldLoadApprovals, loadApprovals } from '../../../../actions/approvals';
import { shouldShowComments } from '../../../../actions/govComments';
import * as exportActionCreators from '../../../../actions/exportProject';
import * as actionCreators from '../../../../actions/project/create/projectCreate';
import { showInviteModal } from '../../../../actions/govPermissions';
import { isBuilderUsed } from '../../ProjectActionToolbar/selectors';
import { ExportButton } from '../../ProjectActionToolbar/ToolbarItems/ExportButton';
import {
    CDSButton,
    CreateFormNotEditable,
    ProjectStatusLabel,
    RouteLeaveWarning,
    SaveModal,
    TemplateProjectHUD,
} from '../../../../components';
import { ProjectModeToggleSDv2 } from './components';
import { ProjectCreateV2FunctionsContext, ProjectCreateV2NavContext } from './context';
import { buildTabRoute, trackEvent } from '../../../../helpers';
import { showConfirmationSimpleModal } from '../../../../actions/confirmation';
import extendProjectForm from '../ProjectCreateHOC';

const { DRAFT, REQUEST_DRAFT } = projectStatusesDict;

const { BATMAN } = basicUserRoles;

function fetchData(getState, dispatch, location, params) {
    const promises = [];

    const state = getState();
    const projectId = Number.parseInt(params.projectId, 10);

    if (shouldLoadSuggestedContent(state)) {
        const governmentId = Number.parseInt(params.governmentId, 10);
        promises.push(dispatch(loadSuggestedContent(governmentId)));
    }

    if (shouldLoadApprovals(state)) {
        promises.push(dispatch(loadApprovals(projectId)));
    }

    if (actionCreators.shouldLoadBuilderSections(state)) {
        promises.push(dispatch(actionCreators.loadBuilderSections(projectId)));
    }

    return Promise.all(promises);
}

const mapStateToProps = (state, props) => {
    const writingPath = getDocumentWritingPath(state, props);
    const isClientLoaded = isInitialClientLoaded(state);
    const project = getProjectJS(state);
    const isDraftPage = project.isIntake
        ? isIntakeDraftPage(state, props)
        : isBuilderDraftPage(state, props);

    return {
        builderDisplayName: getBuilderDisplayName(state),
        builderSectionsMap: getBuilderSectionsJS(state),
        dashboardPath: getDashboardPath(state, props),
        deserializedProject: getDeserializedProject(state),
        disabled: state.projectCreate.get('updating') || state.projectCreate.get('submitting'),
        hasAutoAddenda: hasAutoAddendaSubscription(state),
        formValues: getFormValues(projectCreateFormConfig.form)(state) || {},
        initialValues: isClientLoaded ? getDeserializedProject(state) : undefined,
        isBudgetRequired: getIsBudgetRequired(state), // Used by form `validate` function
        isBudgetUsed: isBudgetUsed(state), // Used by form `validate` function
        isClientLoaded,
        isConfirmation: isConfirmationPage(state, props),
        isBuilderUsed: isBuilderUsed(state),
        isDraftPage,
        isEditableStatus: isDocumentEditable(state),
        isEditor: isDocumentEditor(state),
        isLoading: isLoadingInitialData(state),
        isProjectIdRequired: isProjectIdRequired(state), // Used by form `validate` function
        isPseudoSection: isPseudoSectionPage(state, props),
        isSaveValid: isBuilderSaveValid(state),
        loaded: state.projectCreate.get('loaded'),
        loadInitialDataError: getLoadInitialDataError(state),
        project,
        projectSection: getProjectSection(state, props),
        reviewPath: getDocumentReviewPath(state, props),
        sectionRoutes: getSectionRoutes(state, writingPath),
        sneakyNextRoute: state.projectCreate.get('sneakyNextRoute'),
        shouldExport: state.projectCreate.get('shouldExport'),
        shouldExportDocument: state.projectCreate.get('shouldExportDocument'),
        shouldSneakyUpdate: state.projectCreate.get('shouldSneakyUpdate'),
        shouldSubmit: state.projectCreate.get('shouldSubmit'),
        shouldUpdate: state.projectCreate.get('shouldUpdate'),
        showComments: shouldShowComments(state),
        showFormErrors: state.projectCreate.get('showFormErrors'),
        updating: state.projectCreate.get('updating'),
        user: getUserJS(state),
        // this is passed down to the validation function to do some things differently
        // to adjust for the way the new editor is structured
        validateForSDv2Editor: true,
        writingPath,
        // TODO: pay attention in case this needs to get replaced by the new selector
        // getSDv2WritingSections
        writingSections: getWritingSections(state),
    };
};

const mapDispatchToProps = {
    ...actionCreators,
    ...exportActionCreators,
    initializeProject: actionCreators.initialize,
    initiateSneakyProjectUpdate: actionCreators.initiateSneakyUpdate,
    showConfirmationSimpleModal,
    showInviteModal,
};

const formConfig = {
    ...projectCreateFormConfig,
    validate,
    warn,
};

// @withRouter
// @connectData
// @connect
// @reduxForm
class ConnectedProjectCreateV2 extends Component {
    static propTypes = {
        array: PropTypes.func.isRequired, // reduxForm
        change: PropTypes.func.isRequired, // from reduxForm
        deserializedProject: PropTypes.object,
        destroy: PropTypes.func.isRequired, // from reduxForm
        dirty: PropTypes.bool.isRequired, // from reduxForm
        disabled: PropTypes.bool,
        getFlag: PropTypes.func.isRequired,
        hasAutoAddenda: PropTypes.bool.isRequired,
        initialize: PropTypes.func.isRequired, // from reduxForm
        initiateSave: PropTypes.func.isRequired,
        initializeProject: PropTypes.func.isRequired,
        isBudgetRequired: PropTypes.bool.isRequired,
        isBuilderUsed: PropTypes.bool,
        isClientLoaded: PropTypes.bool.isRequired,
        isDraftPage: PropTypes.bool,
        isEditableStatus: PropTypes.bool.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isSaveValid: PropTypes.bool.isRequired,
        loaded: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
            query: PropTypes.shape({
                activeSection: PropTypes.string,
            }),
            state: PropTypes.shape({
                templateFilter: PropTypes.string,
            }),
        }).isRequired,
        params: PropTypes.object,
        project: PropTypes.shape({
            id: PropTypes.number,
            departmentName: PropTypes.string.isRequired,
            financialId: PropTypes.string,
            isIntake: PropTypes.bool.isRequired,
            status: PropTypes.string.isRequired,
            signatures: PropTypes.array,
            template: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string,
            type: PropTypes.string.isRequired,
            useManualNumbering: PropTypes.bool,
            useSectionDividers: PropTypes.bool.isRequired,
            wasPosted: PropTypes.bool.isRequired,
        }),
        projectSection: PropTypes.shape({
            id: PropTypes.number.isRequired,
            manualNumber: PropTypes.string,
            section_type: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
        }),
        rawUpdateProject: PropTypes.func.isRequired,
        reviewPath: PropTypes.string,
        router: PropTypes.object,
        scrollPageToTop: PropTypes.func.isRequired,
        showConfirmationSimpleModal: PropTypes.func.isRequired,
        showFormErrors: PropTypes.bool.isRequired,
        showHelpModal: PropTypes.func.isRequired,
        showInviteModal: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        valid: PropTypes.bool.isRequired, // (DGW 2/22/19) WARNING: May not be inaccurate (have seen issues before)
    };

    constructor(props) {
        super(props);
        const { query } = props.location;

        let activeSectionId = 0;

        if (query.activeSection && !Number.isNaN(parseInt(query.activeSection, 10))) {
            activeSectionId = parseInt(query.activeSection, 10);
        }

        this.state = {
            manageSectionsDrawerOpened: false,
            activeSectionId,
        };
    }

    componentDidMount() {
        /**
         * Sets the internal state of the form component before mounting (unrelated to redux-form)
         */
        this.props.initializeProject();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { deserializedProject, isClientLoaded, loaded } = this.props;

        const { deserializedProject: nextDeserializedProject } = nextProps;

        // this sets the active section back to zero.
        // doing it in this hook because componentDidUpdate causes a race condition
        // where the previously active section remains active in the new page
        // something?activeSection=4 => somethingElse will have activeSection being 4 in "somethingElse"
        // for a split second
        if (nextProps.location.pathname !== this.props.location.pathname) {
            let activeSectionId = 0;

            if (
                nextProps.location.query?.activeSection &&
                !Number.isNaN(parseInt(nextProps.location.query?.activeSection, 10))
            ) {
                activeSectionId = parseInt(nextProps.location.query?.activeSection, 10);
            }

            this.setState((state) => ({ ...state, activeSectionId }));
        }

        /**
         * (DGW 2/24/19):
         * IMPORTANT: This form includes dates that need to be displayed in the user's timezone.
         * As such we cannot initialize the form with dates from the server as the dates from the
         * server will be adjusted to be in the server's local time. What we need are the dates in
         * the user's browser local time, so we need to wait for the client to load before
         * initializing the form data which includes these timezone dependent dates.
         *
         * The form can get initialized from:
         * 1. Server (via a page refresh or direct link)
         * 2. Front-end (via clicking into the form form another page)
         *
         * When initialized from the client (#2) the dates come from the browser and are properly
         * deserialized. This is handled in the connected `initialValues` prop.
         *
         * This initialization will get called when the form is loaded by the server (#1). The form
         * will not be initialized with values by the server and instead be initialized here
         * once the client has rendered. Doing the initialization this way allows the form to have
         * the correct dates that are localized for the browser's timezone.
         */
        if (nextDeserializedProject && loaded && !isClientLoaded && nextProps.isClientLoaded) {
            this.initializeForm(nextDeserializedProject);
        }

        /**
         * (DGW 2/24/19):
         * NOTE: Current logic prevents this path from happening (project is always loaded on
         * front-end before any route to the form). This could easily change though.
         * That means this path below will not be trafficked.
         */
        if (nextProps.loaded && !loaded) {
            this.initializeForm(nextDeserializedProject);
        }

        /**
         * This only happens when update is called without the sneaky flag.
         * Update will reinitialize the form while sneaky update will save
         * the project without updating/re-initializing the form in order to
         * preserve the form as is (sneaky is a background update)
         */
        if (nextDeserializedProject !== deserializedProject && nextProps.loaded) {
            this.initializeForm(nextDeserializedProject);
        }
    }

    componentWillUnmount() {
        this.props.destroy();
    }

    get styles() {
        return require('../ProjectCreate.scss');
    }

    get draftStatus() {
        const { project } = this.props;

        return [REQUEST_DRAFT, DRAFT].includes(project && project.status);
    }

    saveHandler = () => {
        const {
            hasAutoAddenda,
            isSaveValid,
            project: { wasPosted },
        } = this.props;

        if (wasPosted && isSaveValid) {
            const nextStepText = hasAutoAddenda
                ? 'If you decide to proceed, we will prompt you to create an Addendum ' +
                  'after saving to formally document the change.'
                : 'If you decide to proceed, you should issue an addenda that summarizes ' +
                  'the changes made.';
            return this.props.showConfirmationSimpleModal(
                () => this.props.initiateSave(isSaveValid),
                {
                    bsStyle: 'primary',
                    btnText: 'Save Changes',
                    icon: 'pencil',
                    text:
                        'Warning: Project has been publicly released!\n\n' +
                        'Are you sure you want to proceed with updating the document? ' +
                        'Changes to the published document could effect vendor responses ' +
                        `that have already been started.\n\n${nextStepText}`,
                }
            );
        }

        return this.props.initiateSave(isSaveValid);
    };

    toggleManageSectionsDrawerOpened = () => {
        this.setState((state) => ({
            ...state,
            manageSectionsDrawerOpened: !state.manageSectionsDrawerOpened,
        }));
    };

    setActiveSectionId = (index) => {
        this.setState((state) => ({ ...state, activeSectionId: index }));

        this.props.router.push(`${this.props.location.pathname}?activeSection=${index}`);
    };

    initializeForm = (project) => {
        this.props.initialize(project);
        this.props.initializeProject();
    };

    initiateSneakyUpdate = (nextRoute = null) => {
        const {
            dirty,
            rawUpdateProject,
            project: { wasPosted },
            user: { role },
            scrollPageToTop,
            router,
            valid,
        } = this.props;

        if (!(!dirty || role === BATMAN || (!this.draftStatus && !valid) || wasPosted)) {
            rawUpdateProject({
                notify: true,
                onComplete: () => {
                    if (nextRoute) {
                        scrollPageToTop();
                        router.push(nextRoute);
                    }
                },
            });
        } else if (nextRoute) {
            router.push(nextRoute);
        }
    };

    onInviteClick = () => {
        const { project } = this.props;

        trackEvent('Permissions Modal');
        this.props.showInviteModal(project.id);
    };

    render() {
        const {
            change,
            disabled,
            dirty,
            getFlag,
            isBudgetRequired,
            isDraftPage,
            isEditor,
            isEditableStatus,
            isLoading,
            isSaveValid,
            project,
            showFormErrors,
            user,
            location,
            params,
        } = this.props;

        // Prevent finalized document from being edited in case someone navigates directly
        if (project && !isEditableStatus) {
            const message = isEditor
                ? 'Document has been finalized and is no longer editable'
                : 'You have not received permission to edit this document';

            return <CreateFormNotEditable text={message} />;
        }

        const buildRouteFunction = buildTabRoute(location);

        const shouldShowSignaturesTab = project?.signatures?.filter(
            (s) => !s.isHiddenByLogic
        ).length;
        const tabs = [
            {
                label: 'Project Properties',
                qaTag: 'projectCreate-projectProperties',
                route: buildRouteFunction('project-properties'),
            },
            {
                label: 'Document Editor',
                qaTag: 'projectCreate-documentEditor',
                route: buildRouteFunction('document-editor'),
            },
            {
                label: 'Attachments',
                qaTag: 'projectCreate-attachments',
                route: buildRouteFunction('attachments'),
            },
            ...(getFlag(FLAGS.ENABLE_SIGNATURES_TAB) &&
            project?.type === projectTypesDict.CONTRACT &&
            shouldShowSignaturesTab
                ? [
                      {
                          label: 'Signatures',
                          qaTag: 'projectCreate-signatures',
                          route: buildRouteFunction('signatures'),
                      },
                  ]
                : []),
            ...(getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER) &&
            project.type === projectTypesDict.CONTRACT
                ? [
                      {
                          label: 'Review Checklist',
                          qaTag: 'projectCreate-reviewChecklist',
                          route: buildRouteFunction('review-checklist'),
                      },
                      {
                          label: 'Approvals',
                          qaTag: 'projectCreate-approvals',
                          route: buildRouteFunction('approvals-editor'),
                      },
                  ]
                : [
                      {
                          label: 'Final Review Checklist',
                          qaTag: 'projectCreate-finalReviewChecklist',
                          route: buildRouteFunction('review-checklist'),
                      },
                  ]),
        ];

        const { pathname } = location;
        const currentTabIndex = pathname.lastIndexOf('/');
        const activeTabFromRoute = pathname.substring(currentTabIndex + 1);
        const activeTab = tabs.find((tab) => tab.route.includes(activeTabFromRoute)) || tabs[0];

        if (isLoading) {
            return (
                <div className="row">
                    <div className="col-xs-12 text-center">
                        <h4 className={this.styles.header}>
                            <i className="fa fa-cog fa-spin text-muted" /> Preparing your Project...
                        </h4>
                    </div>
                </div>
            );
        }

        // if the top most parent element here is display: block
        // the layout will break its "100% min-height" thing
        return (
            <>
                <RouteLeaveWarning
                    evaluationFunction={({ nextLocation }) => {
                        if (!dirty) {
                            return false;
                        }

                        return ![
                            'project-properties',
                            'document-editor',
                            'attachments',
                            'review-checklist',
                        ].some((path) => nextLocation.pathname.includes(path));
                    }}
                />
                <ProjectCreateV2FunctionsContext.Provider
                    value={{
                        array: this.props.array,
                        buildRouteFunction,
                        change,
                        disabled,
                        form: formConfig.form,
                        initiateSneakyUpdate: this.initiateSneakyUpdate,
                        isBudgetRequired,
                        location,
                        manageSectionsDrawerOpened: this.state.manageSectionsDrawerOpened,
                        params,
                        project,
                        reviewPath: this.props.reviewPath,
                        router: this.props.router,
                        showFormErrors,
                        showHelpModal: this.props.showHelpModal,
                        showContractCompiler:
                            project.type === projectTypesDict.CONTRACT &&
                            getFlag(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER),
                        toggleManageSectionsDrawerOpened: this.toggleManageSectionsDrawerOpened,
                    }}
                >
                    <ProjectCreateV2NavContext.Provider
                        value={{
                            activeSectionId: this.state.activeSectionId,
                            setActiveSectionId: this.setActiveSectionId,
                        }}
                    >
                        <Box className={this.styles.headerContainer}>
                            <Box px={1.5}>
                                <TemplateProjectHUD
                                    actions={[
                                        ...(isEditor
                                            ? [
                                                  <CDSButton
                                                      disabled={disabled}
                                                      onClick={this.onInviteClick}
                                                      qaTag="projectEdit-invite"
                                                      variant="secondary"
                                                  >
                                                      Invite
                                                  </CDSButton>,
                                              ]
                                            : []),
                                        ...(this.props.isBuilderUsed
                                            ? [
                                                  <ExportButton
                                                      context={isDraftPage ? 'writing' : 'review'}
                                                      disabled={disabled}
                                                      shouldSave={isDraftPage}
                                                      standalone
                                                  >
                                                      Export
                                                  </ExportButton>,
                                              ]
                                            : []),
                                        <CDSButton
                                            aria-label="Save"
                                            disabled={disabled || !isSaveValid}
                                            onClick={this.saveHandler}
                                            qaTag="projectEdit-save"
                                            size="default"
                                            tooltip={
                                                !isSaveValid
                                                    ? 'Cannot save project. See Final Review Checklist for section errors.'
                                                    : undefined
                                            }
                                            variant="primary"
                                        >
                                            Save
                                        </CDSButton>,
                                    ]}
                                    breadcrumbs={{
                                        links: [
                                            {
                                                label: (
                                                    <>
                                                        <i className="fa fa-arrow-left" />{' '}
                                                        Main&nbsp;
                                                        {project.isIntake ? 'Intake' : 'Project'}
                                                        &nbsp;Page
                                                    </>
                                                ),
                                                path: `/governments/${user.government.id}/projects/${project.id}/manage`,
                                            },
                                        ],
                                    }}
                                    department={project.departmentName}
                                    identification={project.financialId || 'None'}
                                    projectLayout
                                    status={{
                                        label: <ProjectStatusLabel status={project.status} />,
                                    }}
                                    title={project.title || 'Untitled'}
                                    type={startCase(project.template.title)}
                                />
                            </Box>
                            <Box
                                px={1.5}
                                sx={{
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        md: 'row',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 5,
                                        order: {
                                            xs: 2,
                                            md: 1,
                                        },
                                    }}
                                >
                                    <TabsNavigation
                                        defaultTab={activeTab}
                                        onClick={this.initiateSneakyUpdate}
                                        redirectFn={browserHistory.push}
                                        tabs={tabs}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        order: {
                                            xs: 1,
                                            md: 2,
                                        },
                                        alignSelf: {
                                            xs: 'flex-end',
                                            md: 'auto',
                                        },
                                    }}
                                >
                                    <ProjectModeToggleSDv2
                                        isIntake={project.isIntake}
                                        location={location}
                                        onClick={this.initiateSneakyUpdate}
                                        params={params}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Outlet />
                    </ProjectCreateV2NavContext.Provider>
                </ProjectCreateV2FunctionsContext.Provider>
                <SaveModal />
            </>
        );
    }
}

export const ProjectCreateV2 = compose(
    connectData(fetchData),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(formConfig),
    extendProjectForm(),
    withFlags()
)(ConnectedProjectCreateV2);
