import React from 'react';
import { Box, Typography, Modal, Grid, Skeleton, IconButton, Button } from '@og-pro/ui';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from 'prop-types';

import { fieldDataTypes } from '@og-pro/shared-config/customFormService/customField';

import { useNavigate, useParams } from 'react-router-dom';

import {
    customFieldsModalContainerStyle,
    customFieldsTileContainerStyle,
    customFieldsTileStyle,
} from './styles';
import { getRequisitionsPath } from '../../../RequisitionsDashboard/RequisitionsDashboard/selectors';

const CustomFieldsModal = ({ onClose, open }) => {
    const navigate = useNavigate();
    const params = useParams();
    const requisitionsPath = getRequisitionsPath({ params });

    function handleFieldTypeClick(fieldType) {
        navigate(`${requisitionsPath}/custom-fields/create/${fieldType}`);
    }

    return (
        <Modal
            aria-describedby="field-modal-description"
            aria-labelledby="field-modal-title"
            onClose={onClose}
            open={open}
        >
            <Box sx={customFieldsModalContainerStyle}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 3,
                        py: 2,
                    }}
                >
                    <Typography component="h4" id="field-modal-title" variant="h4">
                        Choose a Type of Field to Create
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        qaTag="requisitionAdminCustomFields-modalClose"
                        sx={{
                            width: 24,
                            height: 24,
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        flex: 1,
                        pt: 1,
                        pr: 3,
                        pb: 1.5,
                        pl: 3,
                        width: '100%',
                    }}
                >
                    <Grid container spacing={1}>
                        {Object.keys(fieldDataTypes).map((fieldType) => {
                            const { label } = fieldDataTypes[fieldType];
                            return (
                                <Grid item key={label} xs={4}>
                                    <Button
                                        color="secondary"
                                        onClick={() => handleFieldTypeClick(fieldType)}
                                        qaTag={`requisitionAdminCustomFields-${fieldType}`}
                                        sx={customFieldsTileContainerStyle}
                                        to={`${requisitionsPath}/custom-fields/create/${fieldType}`}
                                        variant="text"
                                    >
                                        <Skeleton height={50} variant="rounded" width={100} />
                                        <Typography sx={customFieldsTileStyle} variant="h4">
                                            {label}
                                        </Typography>
                                    </Button>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Box>
        </Modal>
    );
};

CustomFieldsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default CustomFieldsModal;
